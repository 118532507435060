import React from 'react'
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import GlobalStyle from '../GlobalStyle/GlobalStyle'
import Content from '../Content/Content'
import { theme } from "../../utils/theme"
import HeaderWrapper from '../Header/HeaderWrapper/HeaderWrapper'
import FooterWrapper from '../Footer/FooterWrapper/FooterWrapper'

const Layout = ({children}) => {

    return(
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <HeaderWrapper />
            <Content>
                {children}
            </Content>
            <FooterWrapper />
        </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout 