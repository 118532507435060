import styled from 'styled-components'

const MenuListItemStyle = styled.li`

    &:last-of-type {
        content: '';
        margin-top: 20px;
        font-size: 30px;
        border-top: ${({ theme }) => `2px solid ${theme.colors.light}`};
    }
`

export default MenuListItemStyle
