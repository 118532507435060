import styled from 'styled-components'

const CopyrightStyle = styled.div`
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    bottom: 20px;
    width: 100%;
    text-align: center;
`

export default CopyrightStyle