import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        background-color: ${({ theme }) => theme.colors.light};
        color: ${({ theme }) => theme.colors.dark};
        font-family: ${({ theme }) => theme.typography.default};
        font-size: 18px;
        line-height: 1.5em;
    }
    *, *::before, *::after {
        box-sizing: border-box;
    }
`

export default GlobalStyle