import React from 'react'
import MenuListStyle from './MenuListStyle/MenuListStyle'
import homeIcon from '../../../../assets/vectors/house.svg'
import MenuBarIcon from '../../MenuBar/MenuBarIcon/MenuBarIcon'
import LinkStyle from '../../../LinkStyle/LinkStyle'
import MenuListItem from './MenuListItem/MenuListItem'

const MenuList = () => (
    <MenuListStyle>
        <MenuListItem>
            <LinkStyle to='/'><MenuBarIcon src={homeIcon} /></LinkStyle>
        </MenuListItem>
        <MenuListItem>
            <LinkStyle to='/about'>O nas</LinkStyle>
        </MenuListItem>
        <MenuListItem>
            <LinkStyle to='/contact'>Kontakt</LinkStyle>
        </MenuListItem>
        <MenuListItem>
            <LinkStyle as='a' href='https://allegro.pl/uzytkownik/MercePoland' target='_blank'>Sklep</LinkStyle>
        </MenuListItem>
        <MenuListItem>
            <LinkStyle to='/gdpr'>RODO</LinkStyle>
        </MenuListItem>
    </MenuListStyle>
)

export default MenuList