import React from 'react'
import ContactWrapperStyle from './ContactWrapperStyle/ContactWrapperStyle'

const ContactWrapper = ({isMobile}) => (
    <ContactWrapperStyle isMobile={isMobile}>
        <p>Kereskedelmi Kft.</p>
        <p>4400 Nyíregyháza, Víz u. 21/b</p>
        <p>Węgry</p>
        {/* <p>Email</p>
        <p>Phone number</p> */}
    </ContactWrapperStyle>
)

export default ContactWrapper