import React from 'react'
import MenuBar from '../../MainMenu/MenuBar/MenuBar'

import HeaderWrapperStyle from '../HeaderWrapperStyle/HeaderWrapperStyle'
import MenuWrapper from '../../MainMenu/MenuWrapper/MenuWrapper'
import Logo from '../../Logo/Logo'
import HeroSlider from '../HeroSlider/HeroSlider'

class HeaderWrapper extends React.Component {

    state = {
        show: false,
        positionTop: 0,
    };
    
    componentDidMount() {
        window.addEventListener("scroll", this.listenToScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenToScroll);
    }
    
    listenToScroll = () => {
        const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
    
        this.setState({
            positionTop: winScroll,
        });
    };
    
    handleClickToggle = () => {
        this.state.show === false
        ? this.setState({
            show: true,
        })
        : this.setState({
            show: false,
        });
    };
    

    render(){
        return(
            <>
                <HeaderWrapperStyle>
                    <MenuWrapper show={this.state.show} eventclick={this.handleClickToggle} />
                    <Logo isTop />
                </HeaderWrapperStyle>
                <HeroSlider />
                <MenuBar show={this.state.show} eventclick={this.handleClickToggle} />
            </>
        )
    }
}

export default HeaderWrapper
