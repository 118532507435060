import React from 'react'
import {socialMedia} from '../../utils/socialMedia'
import List from '../List/List'

const SocialMediaMenu = () => (
    <List tag='img'>
        {socialMedia}
    </List>
)

export default SocialMediaMenu