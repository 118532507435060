import styled from 'styled-components'

const ContentStyle = styled.div`
    width: 70%;
    min-width: 720px;
    margin: 0 auto 100px;

    @media (max-width: 900px) {
        width: 95%;
        min-width: 0;
        margin: 0 auto 100px;
    }
`

export default ContentStyle