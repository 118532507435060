import styled from 'styled-components'

const FooterWrapperStyle = styled.footer`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    width: 100%;
    background-color: ${({theme}) => theme.colors.black};
    color: ${({theme}) => theme.colors.light};

    @media (max-width: 700px) {
        a {
            font-size: 12px;
        }
    }
`

export default FooterWrapperStyle