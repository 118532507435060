import React from 'react'
import LinkStyle from '../../LinkStyle/LinkStyle'
import ContactWrapper from '../../ContactWrapper/ContactWrapper'
import SocialMediaMenu from '../../SocialMediaMenu/SocialMediaMenu'
import Copyright from '../Copyright/Copyright'
import FooterWrapperStyle from './FooterWrapperStyle/FooterWrapperStyle'

class FooterWrapper extends React.Component {

    state = {
        positionTop: 0,
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenToScroll);
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        this.setState({
            positionTop: winScroll,
        });
    };

    handleClickToTop = (e) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    

    render(){
        return(
            <FooterWrapperStyle>
                    <div>
                        <ContactWrapper isMobile />
                        <LinkStyle to='/gdpr'>Polityka prywatności</LinkStyle>
                    </div>
                    <Copyright handleGoToTop={this.handleClickToTop} />
                    <SocialMediaMenu />
            </FooterWrapperStyle>
        )
    }
}

export default FooterWrapper