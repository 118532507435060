import styled from 'styled-components'

const MenuBarIcon = styled.img`
    padding: 15px;
    opacity: ${({ show }) => show ? '0' : '1'};
    transition: opacity 400ms;
    cursor: pointer;
    width: ${({ isBig }) => isBig ? '105px' : '70px' };

    @media (max-width: 900px) {
        width: 60px;
        transform: ${({ isMobile }) => isMobile ? 'scale(0)' : 'scale(1)' };
    }
`

export default MenuBarIcon
