import styled from 'styled-components'

const LogoStyle = styled.img`
    height: ${({isTop}) => isTop ? '125px' : '80px'};
    padding: ${({isTop}) => isTop ? '15px' : '0'};
    margin: 0;

    @media (max-width: 900px) {
        margin-top: 5px;
        height: ${({isTop}) => isTop ? '70px' : '50px'};
    }
`

export default LogoStyle