import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import HeroSliderStyle from './HeroSliderStyle/HeroSliderStyle'
import HeroSliderItemStyle from './HeroSliderItemStyle/HeroSliderItemStyle'

const HeroSlider = () => {
    const heroes = useStaticQuery(graphql`
        query HeroSliderImages {
            allFile(filter: {relativeDirectory: {eq: "hero"}}) {
                edges {
                    node {
                        publicURL
                        name
                        relativeDirectory
                    }
                }
            }
        }
    `)

    return(
        <HeroSliderStyle>
            <HeroSliderItemStyle key={heroes.allFile.edges[0].node.name} bg={heroes.allFile.edges[0].node.publicURL} />
            {/* {heroes.allFile.edges.map((element) => (
                <HeroSliderItemStyle key={element.node.name} bg={element.node.publicURL} />
            ))} */}
            
        </HeroSliderStyle>
    )
}

export default HeroSlider