import React from 'react'
import basket from '../../../assets/vectors/shopping_cart.svg'
import menuIcon from '../../../assets/vectors/menu.svg'
import MenuBarStyle from './MenuBarStyle/MenuBarStyle'
import MenuBarIcon from './MenuBarIcon/MenuBarIcon'
import LinkStyle from '../../LinkStyle/LinkStyle'

const MenuBar = ({eventclick, show}) => (
    <MenuBarStyle> 
        <LinkStyle as='a' href='https://allegro.pl/uzytkownik/MercePoland' target='_blank'>
            <MenuBarIcon isBig isMobile src={basket} show={show} />
        </LinkStyle>
        <MenuBarIcon isBig src={menuIcon} show={show} onClick={eventclick} />
    </MenuBarStyle>
)

export default MenuBar
