import styled from 'styled-components'

const ListItemStyle = styled.li`
    width: 60px;
    height: 60px;
    padding: 10px;

    img {
        max-width: 40px;
        max-height: 40px;
    }

    @media (max-width: 900px) {
        width: 30px;
        height: 30px;
        padding: 5px;
    
        img {
            max-width: 30px;
            max-height: 30px;
        }
    }
`

export default ListItemStyle