import styled from 'styled-components'

const ListStyle = styled.ul`
    list-style: none;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export default ListStyle