import styled from 'styled-components'

const HeroSliderItemStyle = styled.div`
    @keyframes slide {
        0%, 45% {
            transform: translateX(0);
            opacity: 1;
        }

        47% {
            transform: translateX(100%);
            opacity: 1;
        }

        48% {
            transform: translateX(100%);
            opacity: 0;   
        }
        
        49% {
            tatransform: translateX(-100%);
            opacity: 0;
        }
        
        50%, 98% {
            transform: translateX(-100%);
            opacity: 1; 
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slide2 {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }

        1% {
            transform: translateX(-100%);
            opacity: 0;
        }

        2%, 45% {
            transform: translateX(-100%);
            opacity: 1;
        }

        47%, 98% {
            transform: translateX(0);
            opacity: 1;
        }

        100% {
            transform: translateX(100%);
            opacity: 1;   
        }
    }

    position: absolute;
    width: 100vw;
    height: 734px;
    background-image: ${({ bg }) => `url(${bg})` };
    background-position: center;
    background-size: cover;
    /* animation: slide 30s linear infinite;

    &:nth-of-type(2) {
        animation: slide2 30s linear infinite;
    } */
`

export default HeroSliderItemStyle
