import React from 'react'
import Logo from '../../Logo/Logo'
import CopyrightStyle from './CopyrightStyle/CopyrightStyle'

const Copyright = ({handleGoToTop}) => {
    const year = new Date()

    return(
        <CopyrightStyle>
            <Logo isSmall handleGoToTop={handleGoToTop} />
            © {year.getFullYear()}
        </CopyrightStyle>
    )
}

export default Copyright