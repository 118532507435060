import React from 'react'
import MenuCloseButton from './MenuCloseButton/MenuCloseButton'
import MenuList from './MenuList/MenuList'
import MenuWrapperStyle from './MEnuWrapperStyle/MenuWrapperStyle'

const MenuWrapper = ({eventclick, show}) => (
    <MenuWrapperStyle show={show}>
        <MenuCloseButton eventclick={eventclick} />
        <MenuList />
    </MenuWrapperStyle>
)

export default MenuWrapper