import React from 'react'
import MenuCloseButtonStyle from './MenuCloseButtonStyle/MenuCloceButtonStyle'

import closeBtn from '../../../../assets/vectors/close.svg'
import MenuBarIcon from '../../MenuBar/MenuBarIcon/MenuBarIcon'

const MenuCloseButton = ({eventclick}) => (
    <MenuCloseButtonStyle>
        <MenuBarIcon src={closeBtn} onClick={eventclick} />
    </MenuCloseButtonStyle>
)

export default MenuCloseButton