import styled from 'styled-components'

const HeroSliderStyle = styled.div`
    display: flex;
    width: 100vw;
    height: 734px;
    overflow: hidden;
    transition: transform 200ms ease;
    justify-content: center;
`

export default HeroSliderStyle
