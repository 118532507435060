import styled from 'styled-components'

const ContactWrapperStyle = styled.div`
    padding: 30px 0;
    & p {
        margin: 0;
    }
    
    @media (max-width: 900px) {
        display: ${({ isMobile }) => isMobile ? 'none' : 'static' }
    }
`

export default ContactWrapperStyle