import React from 'react'
import ListItem from './ListItem/ListItem'
import ListStyle from './ListStyle/ListStyle'

const List = ({children, tag}) => (
    <ListStyle>
        {children.map((item) => (
            <ListItem key={item.name} tag={tag}>
                {item}
            </ListItem>
        ))}
    </ListStyle>
)

export default List