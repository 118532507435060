import styled from 'styled-components'

const HeaderWrapperStyle = styled.header`
    position: relative;
    width: 100%;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: top;
    background-color: ${({ theme }) => theme.colors.black };
`

export default HeaderWrapperStyle
