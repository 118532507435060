import styled from 'styled-components'

const MenuWrapperStyle = styled.nav`
    position: fixed;
    top: 0;
    right: 0;
    transform: ${({ show }) => show ? 'translateX(0)' : 'translateX(100%)' };
    height: 100vh;
    width: 250px;
    background-color: ${({ theme }) => theme.colors.black};
    z-index: 10;
    transition: transform 200ms;
`

export default MenuWrapperStyle
