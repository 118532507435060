import styled from 'styled-components'
import ListStyle from '../../../../List/ListStyle/ListStyle'

const MenuListStyle = styled(ListStyle)`
    margin: 0;
    padding: 100px 30px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 35px;
    line-height: 1.5em;
    color: ${({ theme }) => theme.colors.light };
`

export default MenuListStyle
